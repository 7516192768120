/* body * {border: solid 1px red} */


/* .App .MuiContainer-root button:hover {background-color: #fff;} */
.App .MuiContainer-root button {
	/* border: solid 0.25px rgba(0, 0, 0, 0); */
	border: solid 2px #444;
}

.App .MuiContainer-root button:hover {
	background-color: #fff;
	/* border: solid 1px red; */
	border: solid 2px #000;
}
/* .App .MuiContainer-root button:hover svg {fill: #000;} */
.App .MuiContainer-root button svg {fill: #fff;}


.App .MuiContainer-root button.twitter { background-color: #2AA3EF; }
.App .MuiContainer-root button.facebook{ background-color: #3E568C; }
.App .MuiContainer-root button.linkedin{ background-color: #1569BF; }
.App .MuiContainer-root button.dribbble{ background-color: #E84F8A; }
.App .MuiContainer-root button.pintrest{ background-color: #E84F8A; }
.App .MuiContainer-root button.pintrest{ background-color: #E84F8A; }
.App .MuiContainer-root button.reddit{ background-color: #fff; }
.App .MuiContainer-root button.reddit svg{ fill: #FC471E; }
.App .MuiContainer-root button.twitch{ background-color: #9150FB; }
.App .MuiContainer-root button.tumblr{ background-color: #33506B; }
.App .MuiContainer-root button.snapchat { background-color: #fffc00;}
.App .MuiContainer-root button.tiktok{ background-color: #3E568C; }

.App .MuiContainer-root button.youtube{ background-color: #FC0D1B; }
.App .MuiContainer-root button.youtube svg { fill: #fff; }

.App .MuiContainer-root button.trakt{ background-color: #EB212E; }

.App .MuiContainer-root button.github{ background-color: #24292E; }
.App .MuiContainer-root button.flickr{ 
	background: linear-gradient(90deg,  #1067D9 0%, #1067D9 0%, #FC1685 100%);
}

.App .MuiContainer-root button.flipboard{ background-color: #F32C32; }
.App .MuiContainer-root button.soundcloud{ background-color: #FD561F; }



.App .MuiContainer-root button.discord{ background-color: #738BD8; }
.App .MuiContainer-root button.bitbucket{ background-color: #205081; }
.App .MuiContainer-root button.gitlab{ background-color: #FAA238; }
.App .MuiContainer-root button.npm{ background-color: #cb3837; }
.App .MuiContainer-root button.goodreads{ background-color: #B9AD9B; }
.App .MuiContainer-root button.goodreads svg{ fill: #F4F1EB; }
.App .MuiContainer-root button.lastfm{ background-color: #B90711; }
.App .MuiContainer-root button.foursquare{ background-color: #f94877; }


.App .MuiContainer-root button.instagram{ 
	background: linear-gradient(90deg, #FDD97C 0%, #F87E30 25%, #D42E77 50%, #9537BD 75%, #505FD2 100%);
}
 





/* Snapchat */
.App .MuiContainer-root button.snapchat { background: #fffc00;}
.App .MuiContainer-root button.snapchat svg { fill: #000;}
/* .App .MuiContainer-root button.snapchat:hover { background: #fff;} */
/* .App .MuiContainer-root button.snapchat:hover svg { fill: #000;} */



