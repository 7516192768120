
html {
  /* height: 100%; */
  /* background-color: #000; */
  /* background: #2D4D6E; 
  */
  background-color: #375f8a;
}

canvas {
  background: #2D4D6E;
  position: relative;top:0;bottom:0;left:0;right:0;
  display:block;
  height: 100%;
}

body {
  /* position: absolute; */
  /* top:0; bottom:0; */
  /* width:100%; */
  /* height:100%; */
  /* display:block; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-color: #282c34; */
  /* background-color: #375f8a; */
  background-color: #375f8a;
  /* background: radial-gradient(ellipse at top, #375f8a 0%, #060a1f 100%); */
}

.bannerTop
{
  /* border: solid 1px red; */
  /* background-origin: content-box; */
  /* background-attachment: inherit; */
  height:8rem;
  /* overflow: scroll; */
  /* background: url("./../DSC_0333.JPG"); */
  /* background-position: 0% 100%; */
  /* background-size: contain;   */
  
  /* background-position: bottom; */
  /* background-blend-mode: multiply; */
}

/* @media only screen and (min-width: 400px){
  .bannerTop
  {
    height:6rem;
  }
} */

@media only screen and (min-width: 600px){
  .bannerTop
  {
    height:12rem;
  }
}
@media only screen and (max-width: 600px){
  .bannerTop
  {
    height:12rem;
  }
}

@media only screen and (max-width: 400px){
  .bannerTop
  {
    height:8rem;
  }
}

@media only screen and (min-width: 1024px){
  .bannerTop
  {
    height:18rem;
  }
}

.banner-top img {width:100%;position:relative; bottom : 0;}
.banner
{
  /* border-top: solid 0.125rem #444; */
  background: radial-gradient(ellipse at top, #ffffff 0%, #ffffff 40%, #c1c2c5 100%);
  border-bottom: solid 0.125rem #444;
}

.slide-container {
  width:100%;
  height:8rem;

}

.slide-container img {width:100%; height:auto;z-index:88!important;}

.headerbar
{
  /* background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%); */
  background: #090a0f;
  color: #fff;
  /* position: absolute;border-bottom: solid 0.5rem red; */
  top:0;
  width:100%;
  z-index:100;
}

.headerbar ul{
  float:right;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.suit {font-size: 1.75rem;}

.red{
  color: #cc3333;
}

.black{
  color: #000;
}

/* span {
  color: #5785ca;
  font-size:.55rem;
} */

.big{
  height:4000px
}

a { color: #000;}


.image-rotator
{
  width: 100%;
  height: 100%;
  /* border: solid 1px blue; */
  /* background-color: red; */
  position: relative;
  top:0; bottom:0; left:0; right:0;
  overflow: hidden;
  background: #333;
}

.image-rotator div{
  width: 100%;
  height: 100%;
  /* border: solid 1px purple; */
  /* background-color: yellow; */
  position: absolute;
  top:0; bottom:0; left:0; right:0;display:block;

  background-origin: content-box;
  background-position: 0% 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;

  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
    /* background-repeat:   no-repeat; */
    /* background-position: center center;  */
    /* -webkit-background-size: cover; */
  /* -moz-background-size: cover; */
  /* -o-background-size: cover; */
  /* background-size: cover; */
  /* resize: both; */
}

