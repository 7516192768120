
.App-Welcome { 
  position: relative;
  top: -5rem;
  /* border-top: solid 0.125rem #fefefe; */
  background: #fefefe;
  border-bottom: solid 0.5rem #444;
}
.quote
{
  font-weight: bold;
}
.App {
  text-align: center;
}

.Avatar
{
  /* position: absolute; */
  /* top: -4rem; */
  /* border: solid 1px #000; */
  position: "absolute";
  top: "-4rem";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header { */
  /* background-color: #282c34; */
  /* background: radial-gradient(ellipse at bottom, #375f8a 0%, #323338 100%); */
  /* min-height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
  /* padding: 0.5rem; */
/* } */

/* .App-Welcome */
/* { */
  /* position:relative; */
  /* display: flex; */
  /* top:10em; */
  /* top: -5em; */
/* } */
/* .App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

canvas{
  background: red;
  width:100%;height:100%;
}